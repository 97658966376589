import React from 'react'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import ProjectsTabs from '../components/ProjectsTabs';
import Footer from '../components/utility/Footer'
import Navbar from '../components/utility/Navbar';
import Values from '../components/Values';
import heroImage from "../images/haus-01.jpg";
import lenseIcon from "../images/lens.svg";
import responsabilityIcon from "../images/responsability.svg";
import communicationIcon from "../images/communication.svg";
import reliableIcon from "../images/reliable.svg";
import economyIcon from "../images/economy.svg";
import teamworkIcon from "../images/teamwork.svg";
import qualityIcon from "../images/quality.svg";
import { Helmet } from 'react-helmet';

const heroContent = {
  image: heroImage,
  alt: "Ein mehrstöckiges Holzhaus mit großem Garten",
  headline: "Unsere Projekte",
  text: "Ein Haus zu bauen ist meistens eine ziemlich einmalige Entscheidung für viele Jahre. Unsere Stärke ist die individuelle Abstimmung auf unterschiedliche Bedürfnisse, so dass kein Haus wirklich zweimal identisch gebaut wird."
}

const valuesData = [
  {
    icon: lenseIcon,
    alt: "Eine Lupe",
    headline: "Kundenorientierung",
    text: "Ihre Wünsche und Anforderungen sind unser gemeinsames Ziel. Mit einer guten Kommunikation, mit Sorgfalt, Fleiß, Know-How und Erfahrung werden wir es zusammen erreichen."
  },
  {
    icon: responsabilityIcon,
    alt: "Eine Schriftrolle",
    headline: "Verantwortung",
    text: "Wir handeln verantwortungsbewusst, zielorientiert, suchen nach Lösungen und stehen zu unseren Schwächen. Glaubwürdigkeit ist uns wichtig."
  },
  {
    icon: communicationIcon,
    alt: "Sprechblasen ohne Inhalt",
    headline: "Kommunikation",
    text: "Wir sprechen offen, direkt und ehrlich miteinander und geben uns konstruktives Feedback. Respekt, Toleranz und Fairness sind die Grundlage für eine vertrauensvolle Zusammenarbeit, die uns wirklich am Herzen liegt."
  },
  {
    icon: reliableIcon,
    alt: "Ein Burgturm",
    headline: "Zuverlässigkeit",
    text: "Unsere Kunden und Geschäftspartner sollen und können sich auf uns verlassen. Und sollte doch etwas schief gehen nutzen wir die Chance um zu lernen, uns zu verbessern und zu wachsen."
  },
  {
    icon: economyIcon,
    alt: "Ein Prozentzeichen",
    headline: "Wirtschaftlichkeit",
    text: "Wirtschaftlichkeit im Interesse unserer Kunden denken wir immer mit. Wir arbeiten fleißig, zielstrebig, sorgfältig und effizient. Wir sparen Kosten indem wir dank unserer Strukturen viel weniger Aufwand für Marketing, Controlling und Verwaltung benötigen als viele größere Mitbewerber."
  },
  {
    icon: teamworkIcon,
    alt: "Zwei Personen",
    headline: "Teamgeist",
    text: "Wir unterstützen uns im Team und leben ein kollegiales Miteinander, agieren gelassen, habe Freude bei der Arbeit und strahlen Optimismus aus."
  },
  {
    icon: qualityIcon,
    alt: "Eine abstrakte Zeichung eines Diamanten",
    headline: "Qualität",
    text: "Durch erstklassige Aus- und Weiterbildung unserer Mitarbeiter, durch saubere und umsichtige Planung auf dem neuesten Stand der Technik und in Zusammenarbeit mit anderen Fachleuten gewährleisten wir die Ausführung unserer Leistungen auf sehr hohem Qualitäts-Niveau. Wo Menschen arbeiten, passieren auch Fehler. Mit diesen gehen wir konstruktiv um (,sind gut versichert ;-) und haben noch jedes Problem in den Griff bekommen."
  }
]

const ProjektePage = () => {
  return (
    <>
      <Helmet title="Zimmerei Müller - Projekte">
        <meta property="og:title" content="Zimmerei Müller - Projekte" />
        <meta
          property="og:description"
          content="Ein Haus zu bauen ist oft eine einmalige Entscheidung für viele Jahre. Unsere Stärke ist die individuelle Abstimmung auf unterschiedliche Bedürfnisse, so dass kein Haus wirklich zweimal identisch gebaut wird."
        />
        <meta property="og:url" content="https://holzbaumueller.de/projekte" />
        <meta
          name="description"
          content="Ein Haus zu bauen ist oft eine einmalige Entscheidung für viele Jahre. Unsere Stärke ist die individuelle Abstimmung auf unterschiedliche Bedürfnisse, so dass kein Haus wirklich zweimal identisch gebaut wird."
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero image={heroContent.image} headline={heroContent.headline} text={heroContent.text} alt={heroContent.alt} />
        <ProjectsTabs />
        <Values headline="Was uns wichtig ist" values={valuesData} showLines={true} />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default ProjektePage