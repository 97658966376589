import React, { useEffect, useState } from 'react'
import { Projects } from '../types/shared'
import "../style/components/projectstabs.scss"
import homeIcon from "../images/haus.svg";
import smileIcon from "../images/smile.svg";
import renovationIcon from "../images/renovation.svg";
import corporateIcon from "../images/corporate.svg";
import livingImage from "../images/haus-02.jpg";
import increasingImage from "../images/aufstock-01.jpg";
import educatingImage from "../images/schule-02.jpg";
import educatingImage2 from "../images/schule-03.jpg";
import engineeringImage from "../images/ihb-01.jpg";
import engineeringImage2 from "../images/ihb-02.jpg";
import renovationImage from "../images/altbau-01.jpg";
import roofingImage from "../images/dach-01.jpg";
import windowImage from "../images/fenster-01.jpg";
import facadeImage from "../images/fassade-01.jpg"

const tabData = [
  {
    id: Projects.HOME,
    icon: homeIcon,
    alt: "Ein Haus",
    text: "Wohnbau"
  },
  {
    id: Projects.KINDER,
    icon: smileIcon,
    alt: "Ein Quadrat",
    text: "Schulbau"
  },
  {
    id: Projects.ARCHITECTURE,
    icon: corporateIcon,
    alt: "Ein hohes Haus",
    text: "Ingenieurholzbau"
  },
  {
    id: Projects.RENOVATION,
    icon: renovationIcon,
    alt: "Ein Haus mit Fassade",
    text: "Sanierung"
  }
]

const homeData = [
  {
    image: livingImage,
    alt: "Ein freistehendes Haus mit großem Garten",
    headline: "Wohnhäuser",
    text: "Individuell geplant und auf Ihren Erwartungen und Möglichkeiten abgestimmt."
  },
  {
    image: increasingImage,
    headline: "Aufstockungen",
    alt: "Ein Haus mit einer neuen Etage aus Holz",
    text: "Mehr Raum und mehr Möglichkeiten ohne Bauland zu verbrauchen."
  },
  {
    image: engineeringImage,
    headline: "Ingenieurholzbau",
    alt: "Eine Halle beim Bau eines Holzdachs",
    text: "Für Gewerbe, Landwirtschaft, soziale Einrichtungen und vieles mehr."
  },
  {
    image: renovationImage,
    alt: "Ein Altbau mit neuem Holzdach",
    headline: "Sanierung und Erweiterung",
    text: "Wir machen Mehr und Besseres aus dem was Sie schon haben."
  }, {
    image: roofingImage,
    alt: "Ein Haus mit einer Terasse und Überdachung aus Holz",
    headline: "Garagen, Carports, Überdachungen",
    text: "...wenns etwas mehr als von der Stange sein darf."
  },
  {
    image: windowImage,
    alt: "Ein Dachfenster in einem Holzhaus",
    headline: "Dachfenster",
    text: "Aus dunkel mach hell oder aus alt mach neu."
  },
  {
    image: facadeImage,
    alt: "Fassade eines Holzhauses mit Fenstern",
    headline: "Fassaden",
    text: "Wenn es etwas mehr als Putz sein darf."
  }

];

const kinderData = [{
  image: educatingImage,
  alt: "Eine Schule",
  headline: "Kinderhaus",
  text: ""
},
{
  image: educatingImage2,
  alt: "Eine Schule",
  headline: "Schulbau Fassade",
  text: ""
}];

const architectureData = [{
  image: engineeringImage,
  alt: "Eine Halle beim Bau eines Holzdachs",
  headline: "Dachkonstruktion Supermarkt",
  text: ""
},{
  image: engineeringImage2,
  alt: "Eine Halle beim Bau eines Holzdachs",
  headline: "Modulbauweise",
  text: ""
}
];

const renovationData = [{
  image: renovationImage,
  alt: "Ein Altbau mit neuem Holzdach",
  headline: "Sanierung",
  text: ""
}];

const ProjectsTabs = () => {

  const generateProjectCards = (category: Projects) => {
    let data;

    switch (category) {
      case Projects.HOME:
        data = homeData;
        break;
      case Projects.KINDER:
        data = kinderData;
        break;
      case Projects.ARCHITECTURE:
        data = architectureData;
        break;
      case Projects.RENOVATION:
        data = renovationData;
        break;
      default:
        data = homeData;
        break;
    }

    const cards = data.map((project, i) =>
      <div key={`card-${i}-${projectCategory}`} className='project-card'>
        <img className='project-image' src={project.image} alt={project.alt} />
        <h3 className='project-headline'>{project.headline}</h3>
        <p className='project-text'>{project.text}</p>
      </div>
    );

    return <>{cards}</>;
  }

  const [projectCategory, setProjectCategory] = useState<Projects>(Projects.HOME);
  const [projectCards, setProjectCards] = useState<JSX.Element>();

  useEffect(() => {
    setProjectCards(generateProjectCards(projectCategory));
  }, [projectCategory])


  return (
    <section id="projects-tabs">
      <div className='tabs'>
        <div className='wrapper tabs-container'>
          {
            tabData.map((tab) =>
              <button key={`tab-${tab.id}`} className={projectCategory === tab.id ? 'tab active' : 'tab'} onClick={() => setProjectCategory(tab.id)}>
                <img className='tab-icon' src={tab.icon} alt={tab.alt} />
                <span className='tab-text'>{tab.text}</span>
              </button>
            )
          }
        </div>
      </div>
      <div>
        <div className='wrapper'>
          <div className='projects-container'>
            {projectCards}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsTabs